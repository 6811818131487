<template>
  <div class="hls-layout">
    <div class="hls-layout__header">
      <slot name="header" />
    </div>
    <vue-perfect-scrollbar class="hls-layout__sidebar">
      <slot name="sidebar" />
    </vue-perfect-scrollbar>
    <vue-perfect-scrollbar
      class="hls-layout__content"
    >
      <slot />
    </vue-perfect-scrollbar>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  name: 'HlsLayout',
  components: {
    VuePerfectScrollbar,
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/_include'; // Bootstrap includes
@import '~@core/scss/base/components/_include'; // Components includes

.hls-layout{
  display: grid;
  height: calc(100vh - #{$navbar-height});
  padding: 0 $content-padding;

  grid-template-areas:
    "header header"
    "sidebar content"
  ;

  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;

  &__header {
    grid-area: header;
  }

  &__content {
    grid-area: content;
    padding: 20px 1rem 20px calc(30px);
    margin: 0 -1rem 0 0;
    overflow-y: auto;
    & > .ps__rail-y{
      right: 1rem !important;
    }
  }
  &__sidebar {
    grid-area: sidebar;
    padding-top: 20px;
    padding-bottom: 20px;
    overflow-y: auto;
  }

  @include media-breakpoint-down(sm){
    // background: #ff0000;

  }
  @include media-breakpoint-down(xs) {
    padding: 0 calc(#{$content-padding} - 0.8rem);

    grid-template-areas:
      "header header"
      "content content"
     ;
    &__sidebar {
      display: none;
    }

    &__content {
      padding: 20px calc(#{$content-padding} - 0.8rem);
      margin: 0 calc(-#{$content-padding} + 0.8rem);
      & > .ps__rail-y{
        right: 1rem !important;
      }
    }
  }
}
</style>
