<template>
  <div class="category-card">
    <b-link
      class="category-card__img-container"
      :to="to"
    >
      <feather-icon
        icon="ImageIcon"
        class="category-card__img-placeholder"
      />
    </b-link>
    <b-link
      class="category-card__name"
      :to="to"
    >
      <span>{{ category.name }}</span>
    </b-link>
  </div>
</template>

<script>
import {
  BLink,
} from 'bootstrap-vue'

export default {
  name: 'CategoryCard',
  components: {
    BLink,
  },
  props: {
    category: {
      type: Object,
      required: true,
    },
  },
  computed: {
    to() {
      if (!this.category.fake) {
        return {
          name: 'global-catalog',
          params: {
            category: `${this.category.id}`,
          },
        }
      }
      return {
        name: 'global-catalog',
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import '~@core/scss/base/bootstrap-extended/include'; // Bootstrap includes
@import '~@core/scss/base/components/include'; // Components includes

.category-card{
  display: flex;
  flex-direction: column;

  border-radius: 6px;
  background: $card-bg;
  transition: all 0.25s ease;

  // background: rgba(0, 0, 0, 0.0001);
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.06);

  &__img-container{
    width: 100%;
    // height: 0;
    padding-bottom: 100%;
    border-radius: 6px 6px 0 0;
    background: #e2e2e2;
    overflow: hidden;
    position: relative;
    img{
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
    }
  }

  &__img-placeholder{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    color: #6E6B7B;
    width: 40%;
    height: 40%;
  }

  &__name{
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: $headings-color;

    span{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }
  }
  &:hover &__name{
    color: $primary !important;
  }

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 25px 0 rgba($black, 0.25);
  }
}
</style>
