import {
  ref, computed, onMounted, getCurrentInstance, watch,
} from 'vue'
import get from 'lodash/get'
import useCrudList from '@/composables/useCrudList'
import { getCategories as getCategoriesApi, getCategory as getCategoryApi } from '@/services/main-api/catalog/products/categories'
import { getProducts as getProductsApi } from '@/services/main-api/catalog/products/products'
import store from '@/store'

// BEGIN Base
const loading = ref(false)
let instance = null
const params = computed(() => get(instance, '$route.params', {}))
// END Base

// BEGIN CurrentCategory
const rootCategory = {
  id: 'root',
  is_active: 1,
  name: 'Глобальный каталог',
  parent_id: null,
  project_id: null,
  slug: undefined,
  status: 'public',
  fake: true,
  has_children: true,
}

const currentCategory = ref(null)

const getCurrentCategory = async () => {
  const { category } = params.value
  if (!category) {
    currentCategory.value = rootCategory
  } else {
    try {
      const cCategory = (await getCategoryApi({ id: category, includes: 'parent_categories' })).data.data
      if (cCategory.parent_categories && Array.isArray(cCategory.parent_categories)) {
        cCategory.parent_categories.unshift(rootCategory)
      }
      currentCategory.value = cCategory
    } catch (e) {
      currentCategory.value = null
    }
  }
}
// END

// BEGIN Categories
const categories = ref([])

async function getCategories() {
  const { category } = params.value

  try {
    const res = await getCategoriesApi({
      project: store.getters['workingMode/selected_project_id'],
      perPage: 1000,
      sortedBy: 'asc',
      orderBy: 'name',
      page: 1,
      search: `parent_id:${category || null}`,
      searchFields: 'parent_id:=',
      includes: 'children',
      mode: 'by_products',
    })
    categories.value = res.data.data
  } catch (e) {
    categories.value = []
  }
}
// END Categories

// BEGIN Products
async function getProducts() {
  const { category } = params.value
  if (!category) {
    instance.products = []
    return
  }

  let search = null

  const searchFields = [
    `product_category_id:${category}`,
  ]

  if (instance.searchQuery) {
    searchFields.push(`name:${instance.searchQuery}`)
  }

  if (searchFields.length) {
    search = searchFields.join(';')
  }

  try {
    const res = await getProductsApi({
      project: instance.$store.getters['workingMode/selected_project_id'],
      perPage: instance.perPage,
      sortedBy: instance.isSortDirDesc ? 'desc' : 'asc',
      orderBy: instance.sortBy,
      page: instance.currentPage,
      search,
      searchJoin: 'and',
      relationSearchJoin: 'and',
      includes: 'images;product_offers',
    })

    console.log('------')
    console.log(res.data.data)

    instance.products = res.data.data
    instance.currentPage = res.data.meta.current_page
    instance.from = res.data.meta.from
    instance.to = res.data.meta.to
    instance.totalItems = res.data.meta.total
  } catch (e) {
    instance.products = []
  }
}
const sortFields = [
  { label: 'Названию', value: 'name' },
  { label: 'Создан', value: 'created_at' },
  { label: 'Изменен', value: 'updated_at' },
]
const isNoProducts = ref(true)
async function checkHasProducts() {
  try {
    const res = await getProductsApi({
      project: instance.$store.getters['workingMode/selected_project_id'],
      perPage: 1,
      sortedBy: 'asc',
      orderBy: 'id',
      page: 1,
    })
    isNoProducts.value = !get(res, 'data.data.length', 0)
  } catch (e) {
    isNoProducts.value = true
  }
}
// END Products

// BEGIN Meta
const breadcrumb = computed(() => {
  const items = []

  if (
    currentCategory.value
    && currentCategory.value.parent_categories
    && Array.isArray(currentCategory.value.parent_categories)
  ) {
    currentCategory.value.parent_categories.forEach(i => {
      items.push({
        text: i.name,
        active: false,
        to: {
          name: 'global-catalog',
          params: {
            category: i.fake ? undefined : i.id,
          },
        },
      })
    })
  }
  //
  if (currentCategory.value) {
    items.push({
      text: currentCategory.value.name,
      active: true,
    })
  }

  return items
})
// END Meta

// BEGIN Catalog

async function getData() {
  loading.value = true
  await Promise.all([
    await getCategories(),
    await getCurrentCategory(),
    await checkHasProducts(),
  ])

  loading.value = false
}

const showSearchField = ref(false)

async function checkShowSearchField() {
  const { category } = params.value
  if (!category) {
    showSearchField.value = false
    return
  }

  if (currentCategory.value && currentCategory.value.fake) {
    showSearchField.value = false
    return
  }

  try {
    const res = await getProductsApi({
      project: instance.$store.getters['workingMode/selected_project_id'],
      perPage: 1,
      sortedBy: 'asc',
      orderBy: 'id',
      page: 1,
      search: `product_category_id:${category}`,
    })
    showSearchField.value = !!get(res, 'data.data.length', 0)
  } catch (e) {
    showSearchField.value = false
  }
}
// END Catalog

export default function useGlobalCatalog() {
  instance = getCurrentInstance().proxy

  // BEGIN Products
  const {
    loading: productLoading,

    perPageOptions,
    perPage,
    from,
    to,
    currentPage,
    totalItems,

    sortBy,
    searchQuery,
    isSortDirDesc,

    items: products,
  } = useCrudList({
    sortBy: 'name',
    getItems: getProducts,
  })

  watch(() => params.value.category, async () => {
    productLoading.value = true
    await getProducts()
    productLoading.value = false
  })
  // END Offers

  watch(currentCategory, async () => {
    await checkShowSearchField()
  })

  onMounted(async () => {
    await getData()
  })

  watch(params, async () => {
    await getData()
  })

  return {
    // Base
    loading,

    // CurrentCategory
    currentCategory,
    getCurrentCategory,

    // Categories
    categories,
    getCategories,

    // Products,
    products,

    productLoading,
    perPageOptions,
    perPage,
    from,
    to,
    currentPage,
    totalItems,

    sortBy,
    searchQuery,
    isSortDirDesc,
    sortFields,

    // Meta
    breadcrumb,

    // Catalog
    getData,
    isNoProducts,
    params,
    showSearchField,
  }
}

export function useSidebar() {
  return {
    // CurrentCategory
    currentCategory,

    // Categories
    categories,

    // Meta
    breadcrumb,

    // Base
    loading,

    // Catalog
    isNoProducts,
  }
}
