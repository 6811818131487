<template>
  <div class="product-card">
    <div
      class="product-card__image-container"
    >
      <img
        v-if="image"
        :src="image"
        :alt="name"
      >
      <feather-icon
        v-else
        icon="ImageIcon"
        class="product-card__img-placeholder"
      />
      <div
        v-if="countOffers"
        class="product-card__offers-count"
      >
        {{ countOffers }} {{ getNoun(countOffers, 'предложение', 'предложения', 'предложений') }}
      </div>
    </div>
    <div class="product-card__info">
      <div
        class="product-card__name"
      >
        {{ name }}
      </div>
    </div>
    <div class="product-card__actions">
      <router-link
        :to="{
          name: 'offers.create',
          params: {
            product_id: id,
          }
        }"
        tag="a"
        class="btn btn-cart btn-primary"
      >
        <feather-icon
          icon="PlusIcon"
          class="mr-50"
        />
        <span>Предложение</span>
      </router-link>
    </div>
  </div>
</template>

<script>
import {
  BLink, BButton, BBadge, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import get from 'lodash/get'
import { getNoun } from '@/services/stringHelper'

export default {
  name: 'ProductCard',
  components: {
    BLink,
    BButton,
    BBadge,
    BDropdown,
    BDropdownItem,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  computed: {
    id() {
      return get(this.product, 'id', null)
    },
    image() {
      return get(this.product, 'images[0].path', null)
    },
    name() {
      return get(this.product, 'name', '')
    },
    countOffers() {
      if (this.product.product_offers && Array.isArray(this.product.product_offers)) {
        return this.product.product_offers.length
      }
      return 0
    },
  },
  methods: {
    getNoun,
  },
}
</script>

<style scoped lang="scss">
@import '~@core/scss/base/bootstrap-extended/include'; // Bootstrap includes
@import '~@core/scss/base/components/include'; // Components includes

.product-card{
  border-radius: 6px;
  background: $card-bg;
  transition: all 0.25s ease;
  position: relative;

  // background: rgba(0, 0, 0, 0.0001);
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.06);

  &__offers-count{
    position: absolute;
    background:   rgba($success, 0.5);
    color: #fff;
    padding: 0 6px;
    border-radius: 6px;
    left: 10px;
    top: 10px;
    font-size: 12px;
    z-index: 1;
  }

  &__image-container{
    display: block;
    height: 0;
    width: 100%;
    padding-bottom: 100%;
    position: relative;
    // background: #e2e2e2;
    border-radius: 6px 6px 0 0;
    overflow: hidden;
    img{
      position: absolute;
      top: 10px;
      right: 10px;
      left: 10px;
      bottom: 10px;
      width: calc(100% - 20px);
      height: calc(100% - 20px);
      object-fit: contain;
      object-position: center center;
    }
  }

  &__img-placeholder{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    color: #6E6B7B;
    width: 40%;
    height: 40%;
  }

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 25px 0 rgba($black, 0.25);
  }

  &__info{
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 12px 20px;
  }

  &__name{
    height: 63px;
    color: $headings-color;

    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;

    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &__count{
    display: flex;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 20px;
    div:nth-child(1){
      color: $secondary;
      margin-right: 5px;
    }
    div:nth-child(2),div:nth-child(3){
      font-weight: 400;
    }
  }

  &__prices-and-rating{
    height: 36px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  &__prices{
    &__now{
      color: $headings-color;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      text-align: right;
    }
    &__old{
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 15px;
      text-align: right;
      text-decoration-line: line-through;

      color: $gray-100;
    }
  }

  &__reviews{
    color: $gray-100;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
  }

  &__rating{

    ul {
      margin-bottom: 0;
    }
    svg,
    i {
      height: 1.143rem;
      width: 1.143rem;
      font-size: 1.143rem;
    }
  }
  &__actions{
    display: flex;
    flex-wrap: nowrap;
    // flex-wrap: wrap;

    //background: #F8F8F8;
    //height: 42px;
    border-radius: 0 0 6px 6px;
    overflow: hidden;

    .btn-cart,
    .btn-wishlist {
      flex-grow: 1;
      border-radius: 0;
    }

    .btn-wishlist,
    .btn-cart {
      span {
        vertical-align: text-top;
      }
      i,
      svg {
        margin-right: 0.25rem;
        vertical-align: text-top;
        &.text-danger {
          fill: $danger;
        }
      }
    }
  }

  &__labels {
    position:  absolute;
    left: 0;
    top: 0;
    right: 0;
    z-index: 1;
    pointer-events: none;
    padding: 16px 20px;
  }

  &__label{
    font-size: 8px;
  }

  &__dropdown-menu {
    position: absolute !important;
    right: 20px;
    top: 16px;
    z-index: 2;
    background: $card-bg;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
  }
}
</style>
<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include'; // Bootstrap includes
@import '~@core/scss/base/components/include'; // Components includes

.product-card{
  &__dropdown-menu{
    .dropdown-toggle{
      padding: 0;
      margin: 0;
      text-align: center;
      svg{
        color: $gray-100 !important;

      }
    }
  }
}
</style>
