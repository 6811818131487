<template>
  <hls-layout class="global-catalog-page">
    <template #header>
      <header-partial>
        <template #center>
          <div
            v-if="showSearchField"
            class="d-flex align-items-center justify-content-end"
          >
            <b-form-input
              v-model.trim="searchQuery"
              class="d-inline-block"
              placeholder="Поиск..."
            />
          </div>
        </template>
        <template #right />
      </header-partial>
    </template>
    <template #sidebar>
      <catalog-menu-sidebar />
    </template>
    <b-overlay
      :show="loading || productLoading"
      spinner-variant="primary"
      class="global-catalog-page__overlay"
    >

      <app-breadcrumb
        :breadcrumb="breadcrumb"
      />

      <template if="!loading">
        <div
          v-if="isNoProducts"
          class="global-catalog-page__content__empty"
        >
          <b-alert
            variant="primary"
            show
          >
            <div class="alert-body">
              Глобальный каталог пуст!
            </div>
          </b-alert>
        </div>
        <template v-else-if="Array.isArray(categories) && categories.length">
          <b-card
            no-body
          >
            <div class="m-2">
              <b-row align-v="center">
                <b-col
                  cols="12"
                  md=""
                >
                  <h4 class="mb-0">
                    {{ currentCategory.id === 'root' ? 'Категории' : 'Подкатегории' }}
                  </h4>
                </b-col>
                <b-col
                  cols="12"
                  md="auto"
                >
                  <!-- Item View Radio Button Group  -->
                  <b-form-radio-group
                    v-model="categoryViewSelectedType"
                    class="list item-view-radio-group"
                    buttons
                    size="sm"
                    button-variant="outline-primary"
                  >
                    <b-form-radio
                      v-for="option in categoryViewOptions"
                      :key="option.value"
                      :value="option.value"
                    >
                      <feather-icon
                        :icon="option.icon"
                        size="18"
                      />
                    </b-form-radio>
                  </b-form-radio-group>
                </b-col>
              </b-row>
            </div>

            <category-list-table
              v-if="categoryViewSelectedType === 'list-view'"
              :categories="categories"
            />
          </b-card>
          <div
            v-if="categoryViewSelectedType === 'card-view'"
            class="global-catalog-page__categories"
          >
            <b-row>
              <b-col
                v-for="(category, category_index) in categories"
                :key="'sc' + category_index"
                cols="6"
                sm="6"
                md="4"
                lg="3"
                xl="2"
              >
                <category-card
                  class="global-catalog-page__category"
                  :category="category"
                />
              </b-col>
            </b-row>
          </div>
        </template>
      </template>
      <template v-if="!productLoading && Array.isArray(products) && products.length">
        <!-- BEGIN Сортировка -->
        <b-card
          no-body
        >
          <div class="mx-2 my-1">
            <!-- Table Top -->
            <b-row align-v="center">
              <!-- Per Page -->
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>Показать</label>
                <v-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>элементов</label>
              </b-col>

              <!-- Search -->
              <b-col
                cols="12"
                md="6"
              >
                <div class="d-flex align-items-center justify-content-end">
                  <label>Сортировать по</label>
                  <div class="global-catalog-page__sort">
                    <v-select
                      v-model="sortBy"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="sortFields"
                      :clearable="false"
                      class="global-catalog-page__sort__select d-inline-block mx-50"
                      label="label"
                      :reduce="option => option.value"
                    />
                    <b-button
                      variant="outline-secondary"
                      class="btn-icon global-catalog-page__sort__sort-dir mr-50"
                      :class="{'global-catalog-page__sort__sort-dir_active': isSortDirDesc}"
                      @click="isSortDirDesc = !isSortDirDesc"
                    >
                      <feather-icon icon="BarChartIcon" />
                    </b-button>

                    <b-form-radio-group
                      v-model="productViewSelectedType"
                      class="list item-view-radio-group global-catalog-page__product-s-view"
                      buttons
                      size="sm"
                      button-variant="outline-primary"
                    >
                      <b-form-radio
                        v-for="option in productViewOptions"
                        :key="'pwo' + option.value"
                        :value="option.value"
                      >
                        <feather-icon
                          :icon="option.icon"
                          size="18"
                        />
                      </b-form-radio>
                    </b-form-radio-group>
                  </div>

                </div>
              </b-col>
            </b-row>
          </div>
        </b-card>
        <!-- END Сортировка -->

        <!-- BEGIN Products -->
        <div
          v-if="productViewSelectedType === 'card-view'"
          class="global-catalog-page__products"
        >
          <div
            v-for="(product, index_product) in products"
            :key="'p' + index_product"
          >
            <product-card
              class="global-catalog-page__product"
              :product="product"
            />
          </div>
        </div>

        <b-card
          v-if="productViewSelectedType === 'list-view'"
          no-body
        >
          <b-table
            ref="refCompanyListTable"
            class="position-relative list-view-table mb-0"
            :items="products"
            responsive
            :fields="tableColumns"
            primary-key="id"
            :sort-by.sync="sortBy"
            show-empty
            empty-text="Ничего не найдено!"
            :sort-desc.sync="isSortDirDesc"
            no-local-sorting
          >

            <template #cell(name)="data">
              {{ data.item.name }}
            </template>

            <template #cell(product_offers)="data">
              {{ get(data.item, 'product_offers', []).length }}
            </template>

            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <div class="text-nowrap">
                <router-link
                  :to="{
                    name: 'offers.create',
                    params: {
                      product_id: data.item.id,
                    }
                  }"
                  tag="a"
                  class="btn btn-cart btn-primary btn-sm"
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                  />
                  <span>Предложение</span>
                </router-link>

              </div>

            </template>
          </b-table>
        </b-card>
        <!-- END Products -->

        <!-- BEGIN Pagination -->
        <b-card
          no-body
          class="mt-2"
        >
          <div class="mx-2 my-1">
            <b-row>

              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted">Показано с {{ from }} по {{ to }} из {{ totalItems }}</span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >

                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalItems"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-card>
        <!-- END Pagination -->
      </template>
    </b-overlay>

  </hls-layout>
</template>

<script>
import {
  BButton,
  BAlert,
  BOverlay,
  BRow,
  BCol,

  BCard,
  BFormInput,
  BPagination,
  BFormRadio,
  BFormRadioGroup,
  BTooltip,
  BTable,
  BLink,
  BDropdownItem,
  BBadge,
  BDropdown,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import HlsLayout from '@/components/sub-layout/HlsLayout.vue'
import HeaderPartial from '@/components/partials/HeaderPartial.vue'
import AppBreadcrumb from '@/layouts/components/AppBreadcrumb.vue'
import { getNoun } from '@/services/stringHelper'
import useLocalStorage from '@/composables/useLocalStorage'
import { ref } from 'vue'
import get from 'lodash/get'
import CatalogMenuSidebar from '../components/CatalogMenuSidebar.vue'
import useGlobalCatalog from '../composables/useGlobalCatalog'
import CategoryCard from '../components/CategoryCard.vue'
import ProductCard from '../components/ProductCard.vue'
import CategoryListTable from '../components/CategoryListTable.vue'

export default {
  name: 'GlobalCatalog',
  components: {
    BDropdown,
    BBadge,
    BDropdownItem,
    BLink,
    BTable,
    BTooltip,
    BFormRadioGroup,
    BFormRadio,
    ProductCard,
    CategoryCard,
    HeaderPartial,
    HlsLayout,
    AppBreadcrumb,
    CatalogMenuSidebar,

    BButton,
    BAlert,
    BOverlay,
    BRow,
    BCol,

    BCard,
    BFormInput,
    BPagination,

    vSelect,
    CategoryListTable,
  },
  setup() {
    // BEGIN SwitchViewCategory
    const categoryViewSelectedType = useLocalStorage('subcategories-view', 'list-view')
    const categoryViewOptions = [
      { icon: 'ListIcon', value: 'list-view' },
      { icon: 'GridIcon', value: 'card-view' },
    ]
    // END SwitchViewCategory

    // BEGIN SwitchViewProduct
    const productViewSelectedType = useLocalStorage('products-view', 'list-view')
    const productViewOptions = [
      { icon: 'ListIcon', value: 'list-view' },
      { icon: 'GridIcon', value: 'card-view' },
    ]
    // END SwitchViewProduct

    // BEGIN ForProductTable
    const tableColumns = ref([
      { key: 'id', label: 'ID' },
      { key: 'name', label: 'Название' },
      { key: 'product_offers', label: 'Предложений' },
      {
        key: 'actions', label: 'Действия', tdClass: 'text-right', thClass: 'text-right',
      },
    ])
    // END ForProductTable

    const {
      // Base
      loading,

      // CurrentCategory
      currentCategory,

      // Categories
      categories,

      // Products
      products,
      productLoading,
      perPageOptions,
      perPage,
      from,
      to,
      currentPage,
      totalItems,

      sortBy,
      searchQuery,
      isSortDirDesc,
      sortFields,

      // Meta
      breadcrumb,

      // Catalog
      params,
      isNoProducts,
      showSearchField,
    } = useGlobalCatalog()

    return {
      // Base
      loading,

      // CurrentCategory
      currentCategory,

      // Categories
      categories,

      // Products
      products,

      productLoading,
      perPageOptions,
      perPage,
      from,
      to,
      currentPage,
      totalItems,

      sortBy,
      searchQuery,
      isSortDirDesc,
      sortFields,

      // Meta
      breadcrumb,

      // Catalog
      isNoProducts,
      params,
      showSearchField,

      // Other
      getNoun,

      categoryViewSelectedType,
      categoryViewOptions,

      productViewSelectedType,
      productViewOptions,

      tableColumns,

      get,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.content-header .breadcrumb {
  display: flex;
}
</style>

<style scoped lang="scss">
.per-page-selector {
  width: 90px;
}
.list-view-table{
  min-height: 145px;
}

.global-catalog-page{
  &__content{

  }

  &__category{
    margin-bottom: 30px;
  }

  &__categories{
    // margin-bottom: -30px;
  }

  &__products{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    grid-gap: 30px;
    grid-row-gap: 30px;
  }

  &__sort{
    display: flex;

    &__sort-dir{
      svg{
        transform: rotate(90deg);
      }
      &_active{
        svg{
          transform: rotate(90deg) rotateZ(180deg);
        }
      }
    }

    &__select{
      width: 138px;
    }
  }

  &__overlay{
    min-height: 100%;
  }

  &__product-s-view{
    label{
      display: flex;
      align-content: center;
      align-items: center;
    }
  }
}
</style>
