<script setup>
import { defineProps, ref } from 'vue'
import { BTable, BLink } from 'bootstrap-vue'
import get from 'lodash/get'

defineProps({
  categories: {
    type: Array,
    default: () => [],
  },
})

const sortBy = ref('name')
const isSortDirDesc = ref(false)

const tableColumns = [
  { key: 'id', label: 'ID', sortable: false },
  { key: 'name', label: 'Название', sortable: false },
  { key: 'children', label: 'Подкатегорий', sortable: false },
]
</script>

<template>
  <b-table
    ref="refCompanyListTable"
    class="position-relative mb-0"
    :items="categories"
    responsive
    :fields="tableColumns"
    primary-key="id"
    :sort-by.sync="sortBy"
    show-empty
    empty-text="Ничего не найдено!"
    :sort-desc.sync="isSortDirDesc"
    no-local-sorting
  >
    <template #cell(name)="data">
      <b-link :to="{ name: 'global-catalog', params: { category: data.item.id } }">
        {{ data.item.name }}
      </b-link>
    </template>
    <template #cell(children)="data">
      {{ get(data, 'item.children', []).length }}
    </template>
  </b-table>
</template>
